import axios from 'axios';
import api from '@/core/api'
import {ContactModel} from "@/views/contact/models/ContactModel";


export default {
    getProjects({commit}){
        return axios
            .get(api.contact)
            .then((res) => {
                let data = res.data.contacts.items.map(i=>{
                    return new ContactModel(i)
                })
                commit('SET_CONTACTS', data);
            })
    },
}