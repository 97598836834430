import axios from 'axios';
import api from '@/core/api'
import {CarouselModel} from "@/views/home/components/home-slider/models/CarouselModel";


export default {
    getCarousel({commit}){
        return axios
            .get(api.carousel)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.carouselPhotos.items.map(i=>{
                    return new CarouselModel(i,lang)
                })
                commit('SET_CAROUSEL', data);
            })
    }
}