export default {
    SET_LEADS(state, payload) {
        let leads =  payload.filter(item => item.id === 4)
        state.leads = leads;
    },
    SET_ABOUT_TYPES(state,payload){
        if(!state.aboutTypesTitle.length){
            payload.map((item) => {
                state.aboutTypesTitle.push(item.aboutTypes.name);
            })
        }
        state.aboutTypesList = payload;
    },
    SET_WORKSKILLS(state,payload){
        state.workSkills = payload
        if(!state.workSkillsHeader.length){
            payload.map((item) => {
                state.workSkillsHeader.push(item.title)
            })
        }
    },
    SET_FINANCIAL_REPORTS(state,payload){
        state.financialReports = payload
    }
}