export default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "tournaments" */ '../views/home/home.component.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('../views/projects/projects.component.vue')
    },
    {
        path: '/project/:id',
        name: 'project-details',
        component: () => import('../views/projects/components/project-details/project-details.component.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/about.component.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact/contact.component.vue')
    },
    {
        path: '/financial',
        name: 'financial-report',
        component: () => import('../views/financial/financial.component.vue')
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        redirect: {
            name: 'home'
        },
    },
];
