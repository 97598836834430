import axios from 'axios';
import api from '@/core/api'
import {ProjectDetailModel} from "@/views/projects/components/project-details/models/ProjectDetailModel";


export default {
    getProjectsById({commit},payload){
        return axios
            .get(api.projectDetails + payload)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                commit('SET_PROJECTS_DETAILS', new ProjectDetailModel(res.data.project,lang));
                return true;
            }).catch(() => {
                return false;
            })
    }
}