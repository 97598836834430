import axios from 'axios';
import api from '@/core/api'
import {SuggestionSModel} from "@/views/home/components/best-offer/models/SuggestionSModel";



export default {
    getSuggestions({commit}){
        return axios
            .get(api.suggestions)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.suggestions.items.map(i=>{
                    return new SuggestionSModel(i,lang)
                })
                commit('SET_SUGGESTIONS', data);
            })
    }
}