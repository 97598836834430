export default {
    _getSuggestions(state) {
        let suggestionArray = []
        state.suggestions.map((item, index) => {
            if(index !== 0){
                suggestionArray.push(item)
            }
        })
        return suggestionArray;
    },
    _getSuggest(state){
        let suggestObject = {}
        let count = 0
        state.suggestions.map((item,index) => {
            if(count === index){
                suggestObject = item
            }
        })
        return suggestObject
    }
}