import {mapGetters} from "vuex";

export default {
    name: "header-mobile.component.vue",
    data() {
        return {
            toggle: false,
        }
    },
    computed: {
        ...mapGetters({
            lang: '_getLang',
            local: '_getLocal',
            languages: '_getLanguages'
        }),
    },
    methods: {
        activate(lang) {
            this.$store.commit('SET_LANGUAGE', lang)
        }
    },
}
