export default {
    SET_HOME_ABOUTS(state, payload) {
        let newData = [];
        payload.map((item,index) => {
            if(index > 3){
                return false;
            }
            else {
                newData.push(item)
            }
        })
        state.features = newData;
    },
}