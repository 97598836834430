<style src="./footer.component.scss" lang="scss"/>
<template>
  <footer class="footer">
    <div class="footer_bg">
      <img :src="require('../../../assets/images/bg-footer1.png')" alt="">
    </div>
    <div class="footer_back">
      <img :src="require('../../../assets/images/bg-footer2.png')" alt="">
    </div>
    <div class="footer_mobile">
      <img :src="require('../../../assets/images/bg-footer-mobile1.png')" alt="">
    </div>
    <div class="footer_mob">
      <img :src="require('../../../assets/images/bg-footer-mobile2.png')" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9 col-12 footer_col" v-if="local && local.footer">
          <div class="footer-title">
            <h1>{{local.footer.title}}</h1>
            <p class="mt-18">{{local.footer.text}}</p>
            <button @click="handle" class="mt-45 footer-title_btn py-15 px-32">{{local.footer.buttonText}}</button>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-12 footer_col" v-if="local && local.footerNav">
          <div class="footer-navigation mt-80">
            <h4>{{local.footerNav.title}}</h4>
            <ul>
              <li class="mt-20" v-for="(nav, index) in local.nav" :key="index">
                <router-link :to="'/' + nav.linkTo">{{nav.title}}</router-link>
              </li>
              <li class="mt-20" v-if="currentRouteName == 'about'">
                <router-link :to="'/' + local.navFinancial.linkTo">{{local.navFinancial.title}}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script src="./footer.component.js"/>
