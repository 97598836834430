import aboutModule from '../views/about/store';
import carouselModule from '../views/home/components/home-slider/store';
import suggestionsModule from '../views/home/components/best-offer/store';
import featuresModule from '../views/home/components/home-about/store';
import projectsModule from '../views/projects/store';
import projectDetailsModule from '../views/projects/components/project-details/store';
import contactModule from '../views/contact/store';

export default {
    about: aboutModule,
    carousel: carouselModule,
    suggestions: suggestionsModule,
    features: featuresModule,
    proects: projectsModule,
    projectDetails: projectDetailsModule,
    contact: contactModule
}
