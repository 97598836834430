<style lang="scss" src="./header-mobile.component.scss"></style>

<template>
  <div class="mobile">
    <img v-if="!toggle" @click="toggle = !toggle" :src="require('../../../../../assets/images/hamburger.svg')" alt="">
    <img v-else @click="toggle = !toggle" :src="require('../../../../../assets/images/close.svg')" alt="">

    <transition name="fade">
      <div v-if="toggle" class="mobile_content pl-49 py-58 pr-15">
        <div class="mobile_content-lang">
          <ul>
            <li v-for="(item,index) in languages"
                :key="index"
                @click="activate(item)"
                :class="{ active : lang === item }">{{ item }}
            </li>
          </ul>
        </div>
        <div class="mobile_content-nav" v-if="local && local.header">
          <ul>
            <li class="pt-36" @click="toggle = false" v-for="nav in local.header" :key="nav.id">
              <router-link :to="nav.linkto">
                {{ nav.text }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script src="./header-mobile.component.js" lang="js"></script>
