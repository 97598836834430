import axios from 'axios';
import api from '@/core/api'
import {HomeAboutModel} from "@/views/home/components/home-about/models/HomeAboutModel";



export default {
    getAbouts({commit}){
        return axios
            .get(api.features)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.features.items.map(i=>{
                    return new HomeAboutModel(i,lang)
                })
                commit('SET_HOME_ABOUTS', data);
            })
    }
}