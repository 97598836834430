import HeaderComponent from './components/layout/header/header.component.vue'
import FooterComponent from './components/layout/footer/footer.component.vue'
import Spinner from "./core/shared/spinner/spinner.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        HeaderComponent,
        FooterComponent,
        Spinner
    },
    computed: {
        ...mapGetters({
            load: '_getLoader',
            lang: '_getLang'
        })
    },
    created() {
        if(window.localStorage.getItem('lang') === null){
            window.localStorage.setItem('lang', this.lang)
        }else {
            window.localStorage.getItem('lang')
        }
    }
}
