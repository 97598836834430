export default {
    _getHomeAbouts(state) {
        return state.features;
    },
    _getHomeAboutsFile(state){
        let img = [];
        state.features.filter((item) => {
            img.push(item.fileUrl)
        })
        return img;
    }
}