export default {
    _getProjects(state) {
        return state.projects;
    },
    _getNotFinishedProjects(state){
        return state.notFinishedProjects;
    },
    _getPage(state){
        return state.page;
    },
    _getTotalCount(state){
        return state.totalCount;
    }
}
