export class WorkSkillsModel{
    constructor(item,lang) {
        this._setId(item);
        this._setTitle(item,lang);
        this._setDescription(item,lang);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set item
     * set lang
     * @param item
     * @param lang
     * @private
     */
    _setTitle(item, lang) {
        let key = "title_" + lang;
        this.title = item[key];
    }

    /**
     * set item
     * set lang
     * @param item
     * @param lang
     * @private
     */
    _setDescription(item, lang) {
        let key = "description_" + lang;
        this.description = item[key];
    }
}
