export default {
    _getProjectDetails(state) {
        return state.projectDetail;
    },
    _getMainImage(state) {
        let mainImage;
        if(Array.isArray(state.projectDetail.fileUrls)){
            const image = state.projectDetail.fileUrls.filter(item => item.isMain === true)
            image.forEach((item) => {
                mainImage = item;
            })
        }
        return mainImage;
    }
}