export default {
    _getLeads(state) {
        return state.leads;
    },
    _getAboutTypesTitle(state){
        return state.aboutTypesTitle;
    },
    _getAboutTypesList(state){
        return state.aboutTypesList;
    },
    _getWorkSkillsHeader(state){
        return state.workSkillsHeader;
    },
    _getWorkSkills(state){
        return state.workSkills;
    },
    _getFinancialReports(state){
        return state.financialReports;
    }
}