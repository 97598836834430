export default {
    SET_SUGGESTIONS(state, payload) {
        let newData = [];
        payload.map((item,index) => {
            if(index > 3){
                return false
            }else {
                newData.push(item)
            }
        })
        state.suggestions = newData;
    },
}