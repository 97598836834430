import axios from "axios";
import store from './../store/index';
import router from '../router';

axios.defaults.baseURL = "https://api.diadem.az/api/v1/";


axios.interceptors.request.use(function (config) {
    store.commit("SET_LOADER", true);
    return config;
}, function (error) {
    store.commit("SET_LOADER", true);
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => {
        store.commit("SET_LOADER", false);
        return response;
    },
    (error) => {
        const {
            response: {status},
        } = error;

        if (status === 400 || status === 404 || status === 500) {
            router.push({name: 'home'});
        }
        store.commit("SET_LOADER", false);
    }
);

