import {createApp} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import App from './App.vue';
import router from './router';
import store from './store';
import "@/assets/styles/index.scss";
import './core/interceptors';
import Vue3Transitions from 'vue3-transitions'
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
library.add(fas);

const app = createApp(App);
app.use(Vue3Transitions);
app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);
app.use(store).use(router).component('fa', FontAwesomeIcon).mount('#app');
