import axios from 'axios';
import api from '@/core/api'
import {ProjectsModal} from "@/views/projects/models/ProjectsModal";


export default {
    getProjects({commit,state},payload){
        return axios
            .get(api.projects, {params: {pageNumber: state.page.pageNumber, pageSize: state.page.pageSize, finished: payload}})
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let totalCount = res.data.projects.totalCount;
                let data = res.data.projects.items.map(i=>{
                    return new ProjectsModal(i,lang)
                })
                let dataObj = {
                    data: data,
                    totalCount: totalCount
                }
                commit('SET_PROJECTS', dataObj);
            })
    },
    setPagination(context,payload){
        context.commit('SET_PROJECTS_PAGINATION',payload)
    }
}
