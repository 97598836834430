<style src="./header.component.scss" lang="scss"></style>
<template>
  <header class="header">
    <div class="container">
      <div class="header-section px-15 py-12 my-10">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-6">
            <div class="header-section_logo">
              <img @click="pushHome" src="@/assets/images/Logo.png" alt="logo"/>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 col-6">
            <div class="header-section_content pr-19">
              <ul class="header-section_content__navbar pr-10" v-if="local && local.header">
                <li v-for="nav in local.header" :key="nav.id">
                  <router-link :to="nav.linkto">
                    {{ nav.text }}
                  </router-link>
                </li>
              </ul>
              <select @change="changeLanguage" :value="lang">
                <option v-for="(item,index) in languages" :key="index" :value="item.toLocaleLowerCase()">{{ item }}</option>
              </select>
            </div>
            <header-mobile class="header-section_mobile"/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script src="./header.component.js"></script>
