import HeaderMobileComponent from "@/components/layout/header/components/header-mobile/header-mobile.component.vue";
import {mapGetters} from "vuex";

export default {
    name: 'header-component',
    components: {
        'header-mobile': HeaderMobileComponent
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            lang: '_getLang',
            local: '_getLocal',
            languages: '_getLanguages'
        }),
    },
    methods: {
        changeLanguage(e) {
            let l = e.target.value;
            window.localStorage.setItem('lang', l);
            let language = window.localStorage.getItem('lang');
            this.$store.commit('SET_LANGUAGE', language);
            window.location.reload();
        },
        pushHome() {
            this.$router.push({name: 'home'})
        }
    },
    created() {
        let language = window.localStorage.getItem('lang');
        if (language) {
            this.$store.commit('SET_LANGUAGE', language);
        }
    }
}
