import {mapGetters} from "vuex";
import router from "@/router";

export default {
    name: 'footer-component',
    data() {
        return {
            text: 'FOOTER'
        }
    },
    computed: {
        ...mapGetters({
            local: '_getLocal',
        }),
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods:{
        handle(){
            router.push({name: 'contact'})
        }
    }
}
