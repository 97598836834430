export class AboutTypesModel{
    constructor(item,lang) {
        this._setId(item);
        this._setName(item,lang);
    }

    _setId({id}) {
        this.id = id
    }

    _setName(item, lang) {
        let key = "name_" + lang;
        this.name = item[key];
    }
}

export class AboutModel{

    constructor(item,lang) {
        this._setId(item);
        this._setDescription(item,lang);
        this._setAboutTypes(item,lang);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }


    /**
     * set item
     * set lang
     * @param item
     * @param lang
     * @private
     */
    _setDescription(item, lang) {
        let key = "description_" + lang;
        this.description = item[key];
    }

    _setAboutTypes({aboutType}, lang) {
        this.aboutTypes = new AboutTypesModel(aboutType,lang)
    }
}
