export class ProjectsModal{
    constructor(item,lang) {
        this._setId(item);
        this._setName(item,lang);
        this._setStatus(item,lang);
        this._setAddress(item,lang);
        this._setCustomer(item,lang);
        this._setDescription(item,lang);
        this._setFinished(item);
        this._setTitle(item,lang);
        this._setFileUrl(item);
        this._setMainFile(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setName(item, lang) {
        let key = "name_" + lang;
        this.name = item[key];
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setStatus(item, lang) {
        let key = "status_" + lang;
        this.status = item[key];
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setAddress(item, lang) {
        let key = "address_" + lang;
        this.address = item[key];
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setCustomer(item, lang) {
        let key = "customer_" + lang;
        this.customer = item[key];
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setDescription(item, lang) {
        let key = "description_" + lang;
        this.description = item[key];
    }

    /**
     * set item
     * @param item
     * @private
     */
    _setFinished({finished}) {
        this.finished = finished;
    }

    /**
     * set item,lang
     * @param item
     * @param lang
     * @private
     */
    _setTitle(item, lang) {
        let key = "title_" + lang;
        this.title = item[key];
    }

    /**
     * set fileUrl
     * @param item
     * @private
     */
    _setFileUrl({fileUrls}) {
        this.fileUrls = fileUrls;
    }

    /**
     * set mainFile
     * @param item
     * @private
     */
    _setMainFile({fileUrls}) {
        let mainFile;
        if(Array.isArray(fileUrls)){
            mainFile = fileUrls.filter(item => item.isMain === true)
        }
        mainFile.forEach((item) => {
            this.mainPicture = item.link
        })
    }
}
