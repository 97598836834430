import db from './../assets/db/db.json';

export default {
    _getLoader(state) {
        return state.loader;
    },

    _getLang(state) {
        return state.language;
    },

    _getLocal(state) {
        return db[state.language];
    },

    _getLanguages(state) {
        return state.langArray;
    }
}
