import axios from 'axios';
import api from '@/core/api'
import {LeadsModel} from "@/views/about/models/LeadsModel";
import {AboutModel} from "@/views/about/models/AboutModel";
import {WorkSkillsModel} from "@/views/about/models/WorkSkills";
import {FinancialModel} from "@/views/about/models/FinancialModel";


export default {
    getLeads({commit}){
        return axios
            .get(api.leads)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.leads.items.map(i=>{
                    return new LeadsModel(i,lang)
                })
                commit('SET_LEADS', data);
            })
    },
    getAboutType({commit}){
        return axios
            .get(api.abouts)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.abouts.items.map(i=>{
                    return new AboutModel(i,lang)
                })
                commit('SET_ABOUT_TYPES', data);
            })
    },
    getWorkSkills({commit}){
        return axios
            .get(api.workSkills)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.workSkills.items.map(i=>{
                    return new WorkSkillsModel(i,lang)
                })
                commit('SET_WORKSKILLS', data);
            })
    },
    getFinancialReports({commit}){
        return axios
            .get(api.financialReports)
            .then((res) => {
                let lang = localStorage.getItem('lang');
                let data = res.data.financialReport.items.map(i=>{
                    return new FinancialModel(i,lang)
                })
                commit('SET_FINANCIAL_REPORTS', data);
            })
    }

}