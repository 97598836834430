export default {
    abouts: 'home/abouts',
    carousel: 'home/carouselPhotos',
    suggestions: 'home/suggestions',
    features: 'home/features',
    projects: 'home/projects',
    projectDetails: 'home/projects/',
    leads: 'home/leads',
    workSkills: 'home/workSkills',
    contact: 'home/contacts',
    financialReports: 'home/financialreports'
}
